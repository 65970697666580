<script>
import { ref } from 'vue'
import ui from '/~/core/ui'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  components: {
    BaseLoader,
  },
  props: {
    isUploading: {
      type: Boolean,
      default: false,
    },
    loadedPercents: {
      type: Number,
      default: 0,
    },
  },
  emits: ['drop', 'click'],
  setup(props, { emit }) {
    const dragArea = ref(null)
    const isDraggingInside = ref(false)

    function onDragEnter() {
      if (props.isUploading) {
        return
      }

      isDraggingInside.value = true
    }

    function onDragLeave(event) {
      if (dragArea.value?.contains(event.relatedTarget)) {
        return
      }

      isDraggingInside.value = false
    }

    function onDrop(event) {
      if (props.isUploading) {
        return
      }

      isDraggingInside.value = false

      emit('drop', event.dataTransfer.files)
    }

    function onClick() {
      emit('click')
    }

    return {
      dragArea,
      isDraggingInside,
      onDragEnter,
      onDragLeave,
      onDrop,
      onClick,
      ui,
    }
  },
}
</script>

<template>
  <div
    ref="dragArea"
    @dragover.prevent.stop=""
    @dragenter.stop="onDragEnter"
    @dragleave.stop="onDragLeave"
    @drop.prevent.stop="onDrop"
  >
    <slot name="wrapper">
      <div
        class="relative flex h-96 items-center justify-center border-2 border-dashed bg-white p-6 text-center"
        :class="[
          { 'cursor-pointer': !isUploading },
          { 'animate-pulse border-primary': isDraggingInside },
        ]"
        @click="onClick"
      >
        <slot name="content" v-bind="this">
          <div :class="[isUploading && 'opacity-40']">
            <div
              class="text-medium flex flex-col items-center justify-center text-center text-eonx-neutral-600"
            >
              <span>Upload ABA file</span>
              <span v-if="!ui.mobile">or simply drag and drop</span>
            </div>
          </div>
        </slot>
        <slot name="overlay" v-bind="this">
          <div
            v-if="isDraggingInside || isUploading"
            class="absolute top-0 left-0 h-full w-full rounded bg-white opacity-75"
          />
        </slot>
        <slot name="overlay-content" v-bind="this">
          <div
            v-if="isUploading"
            class="absolute top-0 left-0 flex h-full w-full items-center justify-center"
          >
            Uploading {{ loadedPercents }}%
            <base-loader class="ml-2.5" size="xs" />
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>
