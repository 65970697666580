<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  components: {
    BaseIcon,
  },
  props: {
    uploadableFiles: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['remove-file'],
  setup(props, { emit }) {
    function removeFile(file) {
      emit('remove-file', file)
    }

    return {
      removeFile,
    }
  },
}
</script>

<template>
  <div>
    <slot>
      <div
        v-for="(file, index) in uploadableFiles"
        :key="index"
        class="mb-5 mr-5 inline-block align-middle"
      >
        <div
          class="flex items-center rounded-full border-2 bg-white px-[15px] py-[5px] font-bold text-default"
        >
          <base-icon svg="clip" :size="20" class="mr-2.5" />
          <span :class="[file.error && 'text-red-400']">
            {{ file.file.name }}
          </span>
          <span v-if="file.uploading" class="ml-2.5">
            ({{ file.loadedPercents }})%
          </span>
          <button class="ml-6 text-primary" @click="removeFile(file)">
            <base-icon
              svg="circle-close"
              :size="20"
              class="cursor-pointer hover:opacity-60"
            />
          </button>
        </div>
      </div>
    </slot>
  </div>
</template>
